// PodUserAssociationForm.js
import { makeStyles } from '@mui/styles';
import React, { useEffect, useState } from 'react';
import {
  SimpleForm,
  AutocompleteArrayInput,
  TextInput,
  ReferenceArrayInput,
} from 'react-admin';
import { useDataProvider } from 'react-admin';


const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    border: '1px solid #ccc',
    padding: '5px',
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: '1px solid #ccc',
    padding: '5px 0',
  },
  cell: {
    flex: '1',
    padding: '0 5px',
  }
}));

const PodUserAssociationForm = ({ roomId, data, handleSubmit }) => {
  const dataProvider = useDataProvider();
  const [initialValues, setInitialValues] = useState({});
  const [usersList, setUsersList] = useState([]);
  const classes = useStyles();

  const page = 1;
  const perPage = 5;
  const filterToQuery = searchText => ({ email: `${searchText}`});

  const Validate = (values) => {
    const isAllPodUsersArrayNull = (data) => {
      // Iterate through the data and check if any pod users array is not null
      for (const pod of data) {
        if (pod.association && pod.association.length > 0) {
          return false; // Return false if any pod users array is not null or empty
        }
      }
      return true; // Return true if all pod users arrays are null or empty
    };

    // Check if all pod users' arrays are null or empty
    const isAllUsersArrayNull = isAllPodUsersArrayNull(values.pods);

    // Add validation rules and messages based on your requirements
    const errors = {};

    // Example: If all pod users' arrays are null or empty, show an error message
    if (isAllUsersArrayNull) {
      errors.pods = 'At least one user must be associated with each pod';
    }

    // Return the errors object
    return errors;
  };

  const fullNameField = choice => `${choice.firstname} ${choice.lastname}`;


  useEffect(() => {
    // Fetch users for all pods
    Promise.all(
      data.map((pod) => {
        return { podId: pod.id, podName: pod.name, users: pod.trainees || [] };
      })
    ).then((podData) => {
      const initialValues = podData.reduce((acc, pod) => {
        acc[`${pod.podName}`] = {
          podId: pod.podId,
          podName: pod.podName,
          association: pod.users || [],
        };
        return acc;
      }, {});
      setUsersList(podData);
      setInitialValues(initialValues);
      console.log(initialValues, 'initialValues')
    });
  }, [dataProvider, data, page, perPage]);

  return (
    <SimpleForm record={initialValues} onSubmit={handleSubmit}>
      {data.map((pod) => (
        <div key={pod.id} className={classes.row}>

          <TextInput source={`${pod.name}.podName`} label='Pod' disabled className={classes.cell} />
          <ReferenceArrayInput
            source={`${pod.name}.association`}
            reference="users"
            filter={{ roles: 'trainee' }}
            label="users"
            className={classes.cell}
          >
            <AutocompleteArrayInput optionText={fullNameField} filterToQuery={filterToQuery}/>
          </ReferenceArrayInput>
        </div>
      ))}
    </SimpleForm>
  );
};

export default PodUserAssociationForm;
