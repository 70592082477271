import {
    ListItem, ListItemText, List, ListItemIcon, IconButton
} from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import {
    useRecordContext,
} from 'react-admin';
import { makeStyles } from '@mui/styles';


const useStyles = makeStyles((theme) => ({
    listCell: {
        textAlign: 'left',
        width: '100%',
        maxWidth: 360,
        padding: '0 0'
    },
    item: {
        padding: 0,
    },
}));

const ShowRouterInterfaces = () => {
    const record = useRecordContext();
    const sort = { field: 'id', order: 'ASC' };
    const classes = useStyles();

    if (record && record.netifs) {
        const netifs = record.netifs;

        let filteredNetwork = netifs.filter((netif) => {
            return netif.type === 'internal';
        });

        return (
            <>
                <List classes={{ root: classes.listCell }} dense >
                    {filteredNetwork.map((netif) => {
                        return (
                            <ListItem key={netif.addressIp} classes={{ root: classes.item }} disablePadding>
                                <ListItemIcon sx={{ minWidth: '20px' }}>
                                    <IconButton size="small">
                                        <CircleIcon fontSize="small" sx={{ fontSize: '0.50rem' }} />
                                    </IconButton>
                                </ListItemIcon>
                                <ListItemText primary={netif.ip_address}></ListItemText>
                            </ListItem>
                            )
                    }
                    )}
                </List>
            </>
        );
    }
    else return null;
};

export default ShowRouterInterfaces;