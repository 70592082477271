import { Breadcrumbs, Typography } from '@mui/material';
import Link from '@mui/material/Link';
import useBreadcrumbs from 'use-react-router-breadcrumbs';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => {
    return ({
        breadcrumbs: {
            marginBottom: 5,
            color: theme.palette.primary.main,
            marginTop: 10,
            marginLeft: 10,
            display: 'flex',
            alignItems: 'center',
        },
    })
});


function BasicBreadcrumbs(props) {

    const DynamicUserBreadcrumb = () => (
        <span>{props.name}</span>
    );

    const DynamicUserBreadcrumb2 = () => (
        <span>{props.name2}</span>
    );

    const DynamicUserBreadcrumb3 = () => (
        <span>{props.name3}</span>
    );
    const DynamicWorkspaceBreadcrumb = () => (
        <span>{props.name}</span>
    );

    // define custom breadcrumbs for certain routes.
    // breadcrumbs can be components or strings.
    const routes = [
        { path: "/", breadcrumb: "Remote Labs" },
        {
            path: "classrooms",
            children: [
                { path: ":id", breadcrumb: null },
                { path: ":id/show", breadcrumb: DynamicUserBreadcrumb },
                { path: ":id/show/Pod", breadcrumb: 'Pod' },
                { path: ":id/show/Pod/:podId", breadcrumb: DynamicUserBreadcrumb2 },
                { path: ":id/show/Pod/:podId/Instances", breadcrumb: null },
                { path: ":id/show/Pod/:podId/Instances/:vmId", breadcrumb: DynamicUserBreadcrumb3 },
            ],
        },
        {
            path: "users",
            children: [
                { path: ":id", breadcrumb: DynamicUserBreadcrumb },
                { path: ":id/show", breadcrumb: null },
                { path: "create", breadcrumb: 'create' },
            ],
        },
        {
            path: "vms", breadcrumb: "Instances",
            children: [
                { path: ":id", breadcrumb: DynamicUserBreadcrumb },
                { path: ":id/show", breadcrumb: null },
            ],
        },
        {
            path: "images",
            children: [
                { path: ":id", breadcrumb: DynamicUserBreadcrumb },
                { path: ":id/show", breadcrumb: null },
            ],
        },
        {
            path: "trainingTemplates", breadcrumb: "Training Templates",
            children: [
                { path: ":id", breadcrumb: DynamicUserBreadcrumb },
                { path: ":id/show", breadcrumb: null },
                { path: "create", breadcrumb: 'create' },
            ],
        },
        {
            path: "instanceTemplates",  breadcrumb: "Instance Templates",
            children: [
                { path: ":id", breadcrumb: DynamicUserBreadcrumb },
                { path: ":id/show", breadcrumb: null },
                { path: "create", breadcrumb: 'create' },
            ],
        },
        {
            path: "workspaces",
            children: [
                { path: ":id", breadcrumb: null },
                { path: ":id/show", breadcrumb: DynamicWorkspaceBreadcrumb },
                { path: ":id/show/UserPod/:vmId", breadcrumb: DynamicUserBreadcrumb2 },
                { path: ":id/show/CommonPod/:vmId", breadcrumb: DynamicUserBreadcrumb2 },

            ],
        },
        {
            path: "pods",
            children: [
                { path: ":id", breadcrumb: null },
                { path: ":id/show", breadcrumb: DynamicUserBreadcrumb },
                { path: ":id/show/instances", breadcrumb: null },
                { path: ":id/show/instances/:vmId", breadcrumb: DynamicUserBreadcrumb2 },
                { path: ":id/show/networks", breadcrumb: 'Networks' },
                { path: ":id/show/routers", breadcrumb: 'Routers' },
                { path: ":id/show/volumes", breadcrumb: 'Volumes' },
            ],
        },
        {
            path: "mypod", breadcrumb: "My Pod",
            children: [
                { path: "instances", breadcrumb: null },
                { path: "instances/:vmId", breadcrumb: DynamicUserBreadcrumb },

            ],
        },
        {
            path: "myworkspace", breadcrumb: "My Workspace",
            children: [
                { path: "UserPod", breadcrumb: "User Pod" },
                { path: "CommonPod", breadcrumb: "Common Pod" },
                { path: "UserPod/:vmId", breadcrumb: DynamicUserBreadcrumb },
                { path: "CommonPod/:vmId", breadcrumb: DynamicUserBreadcrumb },
            ],
        },
        {
            path: "myclassroom", breadcrumb: "My Classroom",
            children: [
                { path: ":id", breadcrumb: DynamicUserBreadcrumb },
                { path: ":id/show", breadcrumb: null },
                { path: "Pod", breadcrumb: 'Pod' },
                { path: "Pod/:podId", breadcrumb: DynamicUserBreadcrumb2 },
                { path: "Pod/:podId/Instances", breadcrumb: null },
                { path: "Pod/:podId/Instances/:vmId", breadcrumb: DynamicUserBreadcrumb3 },
            ],
        },
        {
            path: "trainings",
            children: [
                { path: ":id", breadcrumb: null },
                { path: ":id/show", breadcrumb: DynamicUserBreadcrumb },
                { path: ":id/show/Pod/:podId/Instances", breadcrumb: null },
                { path: ":id/show/Pod/:podId", breadcrumb: DynamicUserBreadcrumb2 },
                { path: ":id/show/Pod/:podId/Instances/:vmId", breadcrumb: DynamicUserBreadcrumb3 },
                { path: "create", breadcrumb: 'create' },
            ],           
        },
        {
            path: "volumes",
            children: [
                { path: ":id", breadcrumb: DynamicUserBreadcrumb },
                { path: ":id/show", breadcrumb: null },
                { path: ":id/show/details", breadcrumb: null },
            ],
            
        },
    ];


    const breadcrumbs = useBreadcrumbs(routes);
    const classes = useStyles(props);

    const link = (breadcrumb) => {
        return (
            '/#' + breadcrumb
        )
    }

    return (
        <>
            <Breadcrumbs aria-label="breadcrumb" className={classes.breadcrumbs}>
                {breadcrumbs.map(({ breadcrumb }, i) => {
                    if (i + 1 === breadcrumbs.length) {
                        return (
                            <Typography key={i} color="text.primary">
                                {breadcrumb}
                            </Typography>
                        )
                    } else {
                        return (
                            <Link key={i} underline="hover" color="inherit" href={link(breadcrumb.key)} >
                                {breadcrumb}
                            </Link>
                        )
                    }
                }
                )}
            </Breadcrumbs>
        </>
    )
}

export default BasicBreadcrumbs;
