import { useState, useEffect } from 'react';
import {
  useDataProvider, Error, Loading
} from 'react-admin';
import PodShow from './podShow';
import decodeJwt from 'jwt-decode';


const MyPod = ({ permissions, ...props }) => {
  const { sub } = decodeJwt(localStorage.getItem('token'));
  const dataProvider = useDataProvider();
  const [podId, setPodId] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();

  useEffect(() => {
    dataProvider.getOne('users', { id: sub.id })
      .then(({ data }) => {
        if (data && data.pod) {
          setPodId(data.pod);
          setLoading(false);
        }
        else {
          setError('No pod found');
          setLoading(false);
        }
      })
      .catch(error => {
        setError(error);
        setLoading(false);
      })
  }, []);

  if (loading) return <Loading />;
  if (error && error.message) return <Error error={error.message} />;
  if (error) return error;
  if (!podId) return null;


  return (
    <div>
      <PodShow id={podId} resource="pods" />
    </div>
  )

};

export default MyPod;