import { resolveBrowserLocale } from 'react-admin';
import englishMessages from 'ra-language-english';
import frenchMessages from 'ra-language-french';
import polyglotI18nProvider from 'ra-i18n-polyglot';

import englishCustomMessages from '../i18n/en';
import frenchCustomMessages from '../i18n/fr';

const messages = {
    en: { ...englishMessages, ...englishCustomMessages },
    fr: { ...frenchMessages, ...frenchCustomMessages },
};

const i18nProvider = polyglotI18nProvider(
  locale => messages[locale] ? messages[locale] : messages.en,
  resolveBrowserLocale(),
  [{ locale: 'en', name: 'En' }, { locale: 'fr', name: 'Fr' }]
);

export default i18nProvider;