import { stringify } from 'query-string';
import { fetchUtils } from 'ra-core';

const DataProvider = (apiUrl, httpClient = fetchUtils.fetchJson) => {
  return (
    {
      getList: (resource, params) => {
        //console.log(`@@@ getList[${resource},${JSON.stringify(params)}]`);
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;
        /*
        const page = params.pagination && params.pagination.page ? params.pagination.page : 1;
        const perPage = params.pagination && params.pagination.perPage ? params.pagination.perPage : 10;
        const field = params.sort && params.sort.field ? params.sort.field : 'id';
        const order = params.sort && params.sort.order ? params.sort.order : 'ASC';
        */

        //Parameter "openstackAdmin", "Production" are of type boolean and "$regex has to be a string"
        //"pod", "room", "_id" are in format objectId (mongoDB)
        const unusedKey = ["pod", "room", "_id", "openstackAdmin", "production", "flavor", "number"];
        const filters =
          Object.entries(params.filter).map(([key, value]) => {

            if (!unusedKey.includes(key)) return (`"${key}":{"$regex":"${value}"}`)
            else return (`"${key}":"${value}"`);

          });

        //JSON.stringify(fetchUtils.flattenObject(params.filter))
        const query = {
          filter: filters.length > 0 ? fetchUtils.flattenObject(`{${filters}}`) : JSON.stringify(fetchUtils.flattenObject(params.filter)),
          limit: perPage,
          page: page,
          sortBy: `${field}:${order}`,
        };
        const url = `${apiUrl}/${resource}?${stringify(query)}`;

        return httpClient(url).then(({ json }) => {
          //console.log(json, "json")
          if (!json) {
            /*return {
              data: [0],
              total: 0
            };*/
          }
          return {
            data: json.results,
            total: json.totalResults
          };
        });
      },

      getOne: (resource, params) => {
        //console.log(`@@@ getOne[${resource},${JSON.stringify(params)}]`);
        return httpClient(`${apiUrl}/${resource}/${params.id}`).then(({ json }) => ({
          data: json,
        }))
      },

      getOneByFilter: (resource, params) => {
        //console.log(`@@@ getOneByRef[${resource},${JSON.stringify(params)}]`);

        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;

        const query = {
          filter: JSON.stringify(fetchUtils.flattenObject(params.filter)),
          limit: perPage,
          page: page,
          sortBy: `${field}:${order}`,
        };

        const url = `${apiUrl}/${resource}?${stringify(query)}`;
        return httpClient(url).then(({ json }) => {
          if (json.totalResults === 0) {
            throw new Error('Not found');
          }
          else {
            //console.log("json", json)
            return {
              data: json.results,
              total: json.totalResults
            };;
          }
        });

      },

      getMany: (resource, params) => {
        //console.log(`@@@ getMany[${resource},${JSON.stringify(params)}]`);
        //const query = {
        //  id: params.ids,
        //};
        //const url = `${apiUrl}/${resource}?${stringify(query)}`;
        //return httpClient(url).then(({ json }) => ({ data: json }));

        //console.log(`@@@ getMany[${resource},${JSON.stringify(params)}]`);
        return Promise.all(
          params.ids.map(id =>
            httpClient(`${apiUrl}/${resource}/${id}`)
          )
        ).then(responses => ({ data: responses.map(response => response.json) }));
      },

      getManyReference: (resource, params) => {
        //console.log(`@@@ getManyReference[${resource},${JSON.stringify(params)}]`);

        /*
         const query = {
          ...fetchUtils.flattenObject(params.filter),
          [params.target]: params.id,
          _sort: field,
          _order: order,
          
        };
        **/
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;

        const query = {
          filter: JSON.stringify({
            ...params.filter,
            [params.target]: params.id,
          }),
          limit: perPage,
          page: page,
          sortBy: `${field}:${order}`,
        };

        const url = `${apiUrl}/${resource}?${stringify(query)}`;
        //console.log("url", url)

        return httpClient(url).then(({ json }) => {

          if (json.totalResults === 0) {
            //throw new Error(`No ${resource} found`);
            console.log(`No ${resource} found`)
            /* <h1>
               
               <h1/>*/
          }

          return {
            data: json.results,
            total: json.totalResults
          };
        });
      },

      update: (resource, params) => {
        //console.log(`@@@ update[${resource},${JSON.stringify(params)}]`);
        return httpClient(`${apiUrl}/${resource}/${params.id}`, {
          method: 'PUT',
          body: JSON.stringify(params.data),
        }).then(({ json }) => ({ data: json }))
      },

      updateMany: (resource, params) => {
        //console.log(`@@@ updateMany[${resource},${JSON.stringify(params)}]`);
        return Promise.all(
          params.ids.map(id =>
            httpClient(`${apiUrl}/${resource}/${id}`, {
              method: 'PUT',
              body: JSON.stringify(params.data),
            })
          )
        ).then(responses => ({ data: responses.map(({ json }) => json.id) }))
      },

      create: (resource, params) => {
        //console.log(`@@@ create[${resource},${JSON.stringify(params)}]`);
        return httpClient(`${apiUrl}/${resource}`, {
          method: 'POST',
          body: JSON.stringify(params.data),
        }).then(({ json }) => ({
          data: { ...params.data, id: json.id },
        }))
      },

      delete: (resource, params) => {
        //console.log(`@@@ delete[${resource},${JSON.stringify(params)}]`);
        return httpClient(`${apiUrl}/${resource}/${params.id}`, {
          method: 'DELETE',
        }).then(({ json }) => ({ data: { json } }))
      },

      deleteMany: (resource, params) => {
        //console.log(`@@@ deleteMany[${resource},${JSON.stringify(params)}]`);
        return Promise.all(
          params.ids.map(id =>
            httpClient(`${apiUrl}/${resource}/${id}`, {
              method: 'DELETE',
            })
          )
        )
          .then(responses => ({ data: responses.map(({ json }) => json.id) }))
      },
    }
  );
};

export default DataProvider;





