import { Fragment, useState } from 'react';
import {
    useGetManyReference, Datagrid, TextField, ReferenceField,
    TopToolbar, RefreshButton, List, useTranslate,
    BulkDeleteWithConfirmButton,
    useRecordContext,
} from 'react-admin';
import {
    Card,
    Button,
    Toolbar
} from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import VmCreate from '../views/workspaces/vmCreate';
import { ControlVm, CreateInstanceTemplate, OpenVmConsole, RebootVm, StartAllVms, StopAllVms } from '../views/vms/vmControl';
import { makeStyles } from '@mui/styles';
import DeployInstanceTemplate from '../views/pods/instanceTemplateDeploy';
import { Visibility } from '@material-ui/icons';
import ShowRouterInterfaces from '../views/routers/routerInterfaceList';

const useStyles = makeStyles((theme) => ({
    tableLastCell: {
        width: '100%',
        textAlign: 'right'
    },
    descriptionCell: {
        width: '20%'
    },
    nameCell: {
        whiteSpace: 'nowrap',
    },
    button: {
        minWidth: '10px',
        width: '36px',
        height: '32px'
    }
}));
//const classes = useStyles();

// Standardized column widths
const columnWidths = {
    id: '100px',
    width: '400px',
};

const CustomEmpty = () => <div>No data found</div>;

const CustomList = (props) => {
    const [page, setPage] = useState(1);
    const classes = useStyles();
    const perPage = 10;
    const translate = useTranslate();

    const { data, total, isLoading } = useGetManyReference(props.source, {
        target: "pod",
        id: props.id,
        filter: props.filters,
        pagination: { page, perPage },
        sort: { field: 'id', order: 'ASC' }
    });

    if (isLoading) {
        return <div>Loading...</div>;
    };

    const toolbarStyle = {
        minHeight: '8px',
    };

    return (
        <div>
            <Card>
                {props.source === "routers" ? RouterTable(data, translate) : props.source === "networks" ? NetworkTable(data, translate) : VmsTable(data, props, classes, translate)}
            </Card>
            <Toolbar style={toolbarStyle}>
                {page > 1 && <Button onClick={() => setPage(page - 1)}>Previous page</Button>}
                {page < total / perPage && <Button onClick={() => setPage(page + 1)}>Next page</Button>}
            </Toolbar>
        </div>
    );
};

const BulkActionButtons = (props) => (
    <Fragment>
        <BulkDeleteWithConfirmButton resource='vms' />
    </Fragment>
);


const NetworkTable = (props, translate) => {
    return (
        <Datagrid data={props} sort={{ field: 'id', order: 'ASC' }} empty={<CustomEmpty />}
            sx={{ '& .MuiTableCell-root': { width: columnWidths.width } }}>
            <TextField source="name" sx={{ width: columnWidths.name }} label={translate('app.networks.name')} />
            <TextField source="description" sx={{ width: columnWidths.description }} label={translate('app.networks.description')} />
            <TextField source="cidr" label={translate('app.networks.cidr')} />
            <TextField source="osNetworkStatus" label={translate('app.networks.status')} />
            <TextField label='' />
        </Datagrid>
    )
};

const RouterTable = (props, translate) => {
    return (
        <Datagrid data={props} sort={{ field: 'id', order: 'ASC' }} empty={<CustomEmpty />}
            sx={{ '& .MuiTableCell-root': { width: columnWidths.width } }}>
            <TextField source="name" label={translate('app.routers.name')} />
            <TextField source="description" label={translate('app.routers.description')} />
            <ReferenceField source='pod' reference='pods' link='show'>
                <TextField source='name' />
            </ReferenceField>
            <TextField source="osRouterStatus" label={translate('app.routers.status')}/>
            <ShowRouterInterfaces label='Interfaces' />
        </Datagrid>
    )
};


const VmsTable = (data, props, classes, translate) => {
    return (
        <List actions={<Actions data={props} />} >
            <Datagrid data={data} sort={{ field: 'id', order: 'ASC' }} empty={<CustomEmpty />} bulkActionButtons={<BulkActionButtons />}>
                <TextField source="name" label={translate('app.vms.name')} />
                <TextField source="description" cellClassName={classes.descriptionCell} label={translate('app.vms.description')} />
                <ReferenceField source='flavor' reference='flavors' link='show' cellClassName={classes.nameCell}>
                    <TextField source='name' />
                </ReferenceField>
                <TextField source="guest_OS" label={translate('app.vms.guestOS')}/>
                <ReferenceField source='pod' reference='pods' link='show' cellClassName={classes.nameCell}>
                    <TextField source='name' />
                </ReferenceField>
                <TextField source="osServerStatus" label={translate('app.vms.status')} />
                <ListActionButtons cellClassName={classes.tableLastCell} />
            </Datagrid>
        </List>
    )
}

const Actions = (props) => {
    return (
        <TopToolbar>
            <VmCreate pod={props.data} />
            <DeployInstanceTemplate pod={props.data} />
            <StartAllVms pod={props.data} />
            <StopAllVms pod={props.data} />
            <RefreshButton />
        </TopToolbar>
    )
};


const ListActionButtons = (props) => {
    const translate = useTranslate();
    const vm = useRecordContext();
    const location = useLocation();
    const classes = useStyles();

    return (
        <Fragment>
            <Button className={classes.button} size='large'
                component={Link}
                to={`${location.pathname}/${vm.id}`}
                startIcon={<Visibility />}
            >
            </Button>
            <ControlVm action={translate('app.vms.action.start')} />
            <ControlVm action={translate('app.vms.action.stop')} />
            <ControlVm action={translate('app.vms.action.suspend')} />
            <ControlVm action={translate('app.vms.action.resume')} />
            <RebootVm />
            <OpenVmConsole />
            <CreateInstanceTemplate />
        </Fragment>
    );
};


export default CustomList;