import { Fragment, useState } from 'react';
import {
    Create, SimpleForm, TextInput, useTranslate,
    FormDataConsumer, Toolbar, SaveButton, ReferenceInput,
    NumberInput, Button, AutocompleteInput, useNotify,
    useRedirect, useRefresh,
} from 'react-admin';
import { makeStyles } from '@mui/styles';
import { Storage, Description, Add } from '@material-ui/icons';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import { SectionField, ViewTitle } from '../../components';
import { Cancel } from '@mui/icons-material';

const useStyles = makeStyles((theme) => ({
    toolbar: {
        display: 'flex',
    },
    spacer: {
        flex: 1,
    },
    buttonMarginLeft: {
        marginLeft: 10
    },
    flexBox: {
        width: 'auto',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    inputMarginLeft: {
        marginLeft: 20
    },
    border: {
        border: '1px solid #6b489d'
    }
}));

const Validate = (values) => {
    const errors = {};
    if (!values.name) errors.name = 'ra.validation.required';
    if (!values.pod) errors.pod = 'ra.validation.required';
    return errors;
};

const VolumeCreateInDialog = ({ permissions, ...props }) => {
    const translate = useTranslate();
    const [showDialog, setShowDialog] = useState(false);
    const notify = useNotify();
    const redirect = useRedirect();
    const refresh = useRefresh();

    const onSuccess = (data) => {
        notify(translate('app.volumes.create.successMessage'), { type: 'success' });
        redirect('show', '/volumes', data.id);
        refresh();
    };

    const onError = (error) => {

        if (error.body) {
            {
                error.body.detail ? notify(`${error.body.message} : ${error.body.detail.errorMessage}`, { type: 'error' }) :
                    notify(`${error.body.message}`, { type: 'error' })
            };
        }
        else {
            notify('failed', { type: 'failed' });
        }
    };

    const podId = props.pod.id;
    const podName = props.pod.podName;

    const handleClick = () => {
        setShowDialog(true);
    };

    const handleCloseClick = () => {
        setShowDialog(false);
    };

    const postDefaultValue = () => ({ pod: podId });

    const CreateToolbar = (props) => {
        const classes = useStyles();
        const translate = useTranslate();
        return (
            <Toolbar className={classes.toolbar}>
                <Button label="ra.action.cancel" size="medium" onClick={handleCloseClick} className={classes.border} >
                    <Cancel />
                </Button>
                <SaveButton label={translate('app.buttons.create')} className={classes.buttonMarginLeft} />
            </Toolbar>
        );
    };

    return (
        <Fragment>
            <Button label={translate('app.volumes.create.button')} onClick={handleClick} ><Add /></Button>
            <Dialog
                fullWidth
                open={showDialog}
                onClose={handleCloseClick}
                aria-label={translate('app.volumes.create.title')}
            >
                <DialogTitle>{translate('app.volumes.create.title')}</DialogTitle>
                <DialogContent>
                    <ViewTitle label={translate('app.volumes.create.title')} icon={Storage} />
                    <Create resource='volumes' mutationOptions={{ onSuccess: onSuccess, onError }}>
                        <SimpleForm validate={Validate} toolbar={<CreateToolbar />} defaultValues={postDefaultValue}>
                            <AutocompleteInput source="pod" choices={[{ id: podId, name: podName }]} optionText="name" />
                            <SectionField label={translate('app.volumes.create.data')} icon={Description} />
                            <TextInput variant='outlined' source='name' />
                            <TextInput variant='outlined' source='description' />
                            <NumberInput variant='outlined' source='size' label={translate('app.volumes.create.size')} min='0' />
                            <AutocompleteInput source="source" choices={[
                                { id: 'blank', name: 'Blank' },
                                { id: 'image', name: 'Image' }
                            ]} emptyValue='blank' />
                            <FormDataConsumer>
                                {({ formData, ...rest }) => (
                                    formData.source === "image" ?
                                        <ReferenceInput
                                            source="imageRef"
                                            reference="images"
                                            label={translate('app.volumes.create.image')}
                                        >
                                            <AutocompleteInput optionText="name" />
                                        </ReferenceInput> : " "
                                )}
                            </FormDataConsumer>
                        </SimpleForm>
                    </Create>
                </DialogContent>
            </Dialog>
        </Fragment>
    );
};

export default VolumeCreateInDialog;
