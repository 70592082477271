import { useState, useEffect } from 'react';
import {
  useDataProvider, Error, Loading, useTranslate, Button
} from 'react-admin';
import ClassroomShow from './classroomShow';
import decodeJwt from 'jwt-decode';
import { Description } from '@material-ui/icons';
import { SectionField } from '../../components';
import ComponentTitle from '../../components/componentTitle';
import { CastForEducation } from '@mui/icons-material';
import BasicBreadcrumbs from '../../components/breadcrumb';

const MyClassroom = ({ permissions, ...props }) => {
  const { sub } = decodeJwt(localStorage.getItem('token'));
  const dataProvider = useDataProvider();
  const [classroomsId, setClassroomsId] = useState([]);
  const [classrooms, setClassrooms] = useState([]);
  const [classroomSelected, setClassroomSelected] = useState();
  const [loading, setLoading] = useState(true);
  const [classroomsLoading, setClassroomsLoading] = useState(true);
  const [error, setError] = useState();
  const translate = useTranslate();

  useEffect(() => {
    dataProvider.getOne('users', { id: sub.id })
      .then(({ data }) => {
        if (data && data.classroom.length > 0) {
          setClassroomsId(data.classroom);
          setLoading(false);
        }
        else {
          setError(translate('app.classrooms.myclassroom.errorMessage'));
          setLoading(false);
        }
      })
      .catch(error => {
        setError(error);
        setLoading(false);
      })
  }, [sub.id]);

  useEffect(() => {
    if (classroomsId.length > 1) {
      classroomsId.map((id) => {
        dataProvider.getOne('classrooms', { id })
          .then(({ data }) => {
            if (data) {
              setClassrooms(classrooms => [...classrooms, [id, data.name]]);
              setClassroomsLoading(false);
            }
          })
          .catch(error => {
            setError(error);
            setClassroomsLoading(false);
          })
      })
    }
  }, [classroomsId]);

  if (loading) return <Loading />;
  if (classroomsId.length > 1) {
    if (classroomsLoading) return <Loading />;
  };
  if (error && error.message)
    return (
      <>
        <ComponentTitle label={translate('resources.myclassroom.name')} icon={CastForEducation} />
        <Error error={error.message} />
      </>
    );

  if (error)
    return (
      <>
        <BasicBreadcrumbs />
        <ComponentTitle label={translate('resources.myclassroom.name')} icon={CastForEducation} />
        {error}
      </>
    );

  if (!classroomsId) return null;

  return (
    <div>
      {classroomsId.length > 1 ?
        <>
          <ComponentTitle label={translate('resources.myclassroom.name')} icon={CastForEducation} />
          <BasicBreadcrumbs />
          <SectionField label={translate('app.classrooms.title')} icon={Description} />
          {classrooms.map((room) => {
            return (
              <>
                <Button size='medium' onClick={() => setClassroomSelected(room[0])} sx={{ border: '1px solid #6b489d' }}>
                  {room[1]}
                </Button>
                <> </>
              </>
            )
          })}
        </>
        :
        <>
          <BasicBreadcrumbs />
          <ComponentTitle label={translate('resources.myclassroom.name')} icon={CastForEducation} />
          <ClassroomShow id={classroomsId} resource="classrooms" myclassroom='true' />
        </>
      }
      {classroomSelected ?
        <>
          <ComponentTitle label={translate('resources.myclassroom.name')} icon={CastForEducation} />
          <ClassroomShow id={classroomSelected} resource="classrooms" />
        </> : null}
    </div>
  )

};

export default MyClassroom;